import {
  faExclamation,
  faQuestion,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as Redux from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { isLoggedInUser, isLoginStatus, MenuData } from "../../types/security";
import { Logout } from "./Menu";
import { ToggleWithoutArrow, UserMenu, UserMenuProps } from "./UserMenu";
import styles from "./MenuControls.module.css";
import { UnreadMessages } from "./UnreadMessages";
import { FormattedMessage } from "react-intl";
import { BulbComponent } from "../bulb/BulbComponent";
import { SelectionState } from "../../types/selection";
import { messages } from "../..";
import { Dispatch, RootState, Store } from "../../store";
import { setupHomePage } from "../../model/menu";

export const userMenuWithStore = (store: Store) => () => {
  const state = store.getState();
  const loginStatus = state.security?.loginStatus;
  let isSuperUser = isLoginStatus(loginStatus) && loginStatus?.superUser;
  const autorities =
    (isLoginStatus(loginStatus) && loginStatus?.authorities) || undefined;

  let componentProps = {
    selection: state.selection,
    isSuperUser,
    autorities,
  };

  return class extends React.Component<UserMenuProps> {
    render() {
      return <UserMenu {...componentProps} {...this.props} />;
    }
  };
};

export interface MenuControlsProps {
  language: string;
  selection?: SelectionState;
  isSuperUser?: boolean;
  authorities?: string[];
  systemMenu?: MenuData;
  userLabel: string;
  badge?: any;
  isAvatar?: boolean;
  className?: string;
  style?: React.CSSProperties;
  setupHomePage: () => void;
  changePassword: () => void;
  logout: () => void;
  invokeAvatarHelp?: () => void;
}

export const MenuControls: React.FC<MenuControlsProps> = ({
  changePassword,
  invokeAvatarHelp,
  isSuperUser,
  authorities,
  selection,
  badge,
  isAvatar,
  language,
  logout,
  setupHomePage,
  userLabel: label,
  systemMenu,
  className,
}) => {
  const userLabel = !isAvatar ? (
    label
  ) : (
    <FormattedMessage id="NPT_AVATAR_LABEL" />
  );
  const menuBadge = badge;
  return (
    <div
      key="menu-controls"
      className={`ml-auto nav mr-2 menu-controls ${className}`}
    >
      {isAvatar && (
        <Nav.Item key="" className="  px-2">
          <BulbComponent type="warning">
            <span
              onClick={invokeAvatarHelp}
              className="w-100 h-100 d-flex justify-content-center pt-1"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon className="text-info" icon={faExclamation} />
            </span>
          </BulbComponent>
        </Nav.Item>
      )}
      <Nav.Item key="menu-user-label" className="  px-2">
        <Link className={styles.item} to="/help">
          <FontAwesomeIcon icon={faQuestion} />
        </Link>
      </Nav.Item>
      <UserMenu
        language={language}
        extended={true}
        badge={menuBadge}
        setupHomePage={setupHomePage}
        systemMenu={systemMenu}
        changePassword={changePassword}
        title={<FontAwesomeIcon className="" icon={faUserCircle} />}
        isSuperUser={isSuperUser}
        authorities={authorities}
        selection={selection}
      />
      <Nav.Item key={`menu-user-label ${styles.item}`} className=" px-2">
        {userLabel}
      </Nav.Item>

      <div key="menu-user-logout" className={styles.item}>
        <Logout className="px-1 " logout={logout} />
      </div>
    </div>
  );
};

export default connect(
  (state: RootState) => {
    const loginStatus = state.security?.loginStatus;
    if (isLoggedInUser(loginStatus)) {
      let isSuperUser = isLoginStatus(loginStatus)
        ? loginStatus?.superUser
        : false;
      const authorities =
        (isLoginStatus(loginStatus) && loginStatus?.authorities) || undefined;
      const selection = state.selection;
      return {
        language: state.locale.language,
        isSuperUser,
        authorities,
        selection,
        userLabel: loginStatus.label,
        systemMenu: loginStatus.systemMenu,
      };
    }
    return {
      language: state.locale?.language || "",
      userLabel: "",
      dark: false,
    };
  },
  (dispatch: Dispatch) => {
    return {
      changePassword: () => {
        dispatch.menu.changePassword();
      },
      setupHomePage: () => {
        setupHomePage();
      },
      logout: () => {
        dispatch.security.logout();
      },
    };
  }
)(MenuControls);
