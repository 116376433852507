import * as React from "react";

import { FormattedMessage } from "react-intl";
import { useDrag, useDrop } from "react-dnd";
import { LogLevel, Virtuoso } from "react-virtuoso";
import {
  Column,
  ColumnTemplateMap,
  CurrencyColumn,
  RowData,
  TableDragOptions,
  TableDropOptions,
} from "../../types/table";
import { FetchError } from "../../types/error";
import { OnScrollParams } from "./TableContainer";
import { COLUMN_MIN_WIDTH } from "../../constants/table";
import {
  getRowValue,
  parseStyles,
  valueToDateString,
  valueToDateTimeString,
} from "../../services/table";
import { retrieveFunction } from "../../services/automation";

import { LoadingAlert, LoadingError } from "./Table";

import styles from "./Table.module.css";
import { Link } from "react-router-dom";
import { CSSProperties, FC } from "react";
import { isFunctionalComponent } from "../../services/app";
import PerfectScrollbar from "react-perfect-scrollbar";

/**Properties of Table body */
interface TableBodyProps {
  scrollable?: boolean;
  column?: ColumnTemplateMap;
  body?: FC;
  autoresize?: boolean;
  /**Context path of application (needed for link cells)*/
  contextPath: string;
  /**Width of table body*/
  width: number;
  /**Calculated height of table body*/
  bodyHeight?: number;
  /**Display indicator that table data is loading*/
  loadingData: boolean;
  /**Info about error that occured during data loading*/
  errorData: null | FetchError;
  /**Actual width of table (can be larger than visible part) */
  tableWidth: number;
  /**Current horizontal scroll position*/
  scrollLeft: number;
  /**Current vertical scroll position*/
  scrollTop: number;
  /**Stylesheets map of table*/
  stylesheets: { [k: string]: string };
  /**Array of table columns*/
  columns: Column[];
  /**Array of table columns widths*/
  columnsWidths: number[] | null;
  /**Array of table rows ids*/
  pageRows: number[];
  /**Map of row data by it idx */
  rowByIdx: { [rowIdx: number]: RowData };
  /**Map of row selected flag by key */
  selectedRows: { [k: string]: boolean };
  /**Table drag options */
  dragOptions: TableDragOptions | null;
  /**Table drop options */
  dropOptions: TableDropOptions | null;
  /**Synchronize scrolling function */
  onScroll: (params: OnScrollParams) => void;
  /**Select row function */
  selectRow: (key: string) => void;
  /**Resolving cell/row drop handler */
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}

/********************************
 *     Table Body Component     *
 ********************************/
export default class TableBody extends React.PureComponent<TableBodyProps> {
  scrollbarRef: any | null = null;
  private rowHeight: number = 80;
  constructor(props: TableBodyProps) {
    super(props);

    this.cellRenderer = this.cellRenderer.bind(this);
    // this.noContentRenderer = this.noContentRenderer.bind(this);
    this.onScrollX = this.onScrollX.bind(this);
    this.onScrollY = this.onScrollY.bind(this);
  }

  onScrollX(container: HTMLElement) {
    if (this.props.loadingData) {
      return;
    }
    this.props.onScroll({ scrollLeft: container.scrollLeft });
  }

  onScrollY(container: HTMLElement) {
    this.props.onScroll({ scrollTop: container.scrollTop });
  }
  renderExternalBody = () => {
    const { body } = this.props;
    if (!body) {
      return null;
    }
    if (isFunctionalComponent(body)) {
      const BodyComponent = body;
      return <BodyComponent />;
    }
    return null;
  };
  externalCellRenderer(data: {
    columnIndex: number; // Horizontal (column) index of cell
    isScrolling: boolean; // The Grid is currently being scrolled
    isVisible: boolean; // This cell is visible within the grid (eg it is not an overscanned cell)
    key: string; // Unique key within array of cells
    parent: any; // Reference to the parent Grid (instance)
    rowIndex: number; // Vertical (row) index of cell
    style: any; // Style object to be applied to cell (to position it);
    // This must be passed through to the rendered cell element.
  }) {}

  renderScrollArea = () => {
    return React.forwardRef(({ style, ...props }: any, ref) => {
      // an alternative option to assign the ref is
      // <div ref={(r) => ref.current = r}>
      return (
        <div
          style={{ ...style, border: "5px solid gray" }}
          ref={ref}
          {...props}
        />
      );
    });
  };

  cellRenderer(data: {
    // columnIndex: number; // Horizontal (column) index of cell
    // isScrolling: boolean; // The Grid is currently being scrolled
    // isVisible: boolean; // This cell is visible within the grid (eg it is not an overscanned cell)
    key: string; // Unique key within array of cells
    // parent: any; // Reference to the parent Grid (instance)
    rowIndex: number; // Vertical (row) index of cell
    style: any; // Style object to be applied to cell (to position it);
    // This must be passed through to the rendered cell element.
  }) {
    if (this.props.loadingData) {
      return (
        <div className="text-center" style={{ width: this.props.tableWidth }}>
          <div
            className="position-relative"
            style={{ width: this.props.width, left: this.props.scrollLeft }}
          >
            <LoadingAlert />
          </div>
        </div>
      );
    }
    if (this.props.errorData) {
      return (
        <div className="text-center" style={{ width: this.props.tableWidth }}>
          <div
            className="position-relative"
            style={{ width: this.props.width, left: this.props.scrollLeft }}
          >
            <LoadingError />
          </div>
        </div>
      );
    }
    if (!this.props.pageRows.length) {
      return (
        <div
          className={`${styles.nptTableRow} text-center`}
          style={{ width: this.props.tableWidth }}
        >
          <div
            className="position-relative"
            style={{ width: this.props.width, left: this.props.scrollLeft }}
          >
            <FormattedMessage
              id="NPT_TABLE_NO_DATA"
              defaultMessage="No data"
              description="Table have no data"
            />
          </div>
        </div>
      );
    }

    // Style is required since it specifies how the cell is to be sized and positioned.
    // React Virtualized depends on this sizing/positioning for proper scrolling behavior.
    // By default, the grid component provides the following style properties:
    //    position
    //    left
    //    top
    //    height
    //    width
    // You can add additional class names or style properties as you would like.
    // Key is also required by React to more efficiently manage the array of cells.
    const rowIndex = this.props.pageRows[data.rowIndex];
    const row: RowData = this.props.rowByIdx[
      rowIndex
      //   this.props.pageRows[data.rowIndex]
    ] || {
      changedData: {},
      bindedData: {},
      data: { __unavailable: true },
      classes: {},
      changed: null,
    };

    return (
      <Row
        column={this.props.column}
        key={data.key}
        autoresize={this.props.autoresize}
        style={data.style}
        isOdd={data.rowIndex % 2 === 1}
        row={row}
        rowIdx={rowIndex}
        // rowIdx={data.rowIndex}
        contextPath={this.props.contextPath}
        stylesheets={this.props.stylesheets}
        columns={this.props.columns}
        columnsWidths={this.props.columnsWidths}
        selectedRows={this.props.selectedRows}
        dragOptions={this.props.dragOptions}
        dropOptions={this.props.dropOptions}
        selectRow={this.props.selectRow}
        resolveDrop={this.props.resolveDrop}
      />
    );
  }
  renderRowItem = (index: number) => {
    return this.cellRenderer({
      key: index.toString(),
      rowIndex: index,
      style: {
        width: this.props.autoresize ? "100%" : this.props.tableWidth,
        // height: this.rowHeight,
      },
    });
  };
  scrollHandling = () => {
    if (!this.scrollbarRef) {
      return;
    }
    if (this.scrollbarRef.scrollLeft !== this.props.scrollLeft) {
      this.onScrollX(this.scrollbarRef);
    }
    if (this.scrollbarRef.scrollTop !== this.props.scrollTop) {
      this.onScrollY(this.scrollbarRef);
    }
  };
  getTotalCount = () => {
    const hasError = !!this.props.errorData;
    if (this.props.loadingData) {
      return 1;
    }
    if (hasError) {
      return 1;
    }
    if (!this.props.pageRows.length) {
      return 1;
    }
    return this.props.pageRows.length;
  };
  renderGrid = () => {
    const externalBody = this.renderExternalBody();

    if (externalBody) {
      return externalBody;
    }

    const hasError = !!this.props.errorData;
    return (
      <Virtuoso
        useWindowScroll
        customScrollParent={this.scrollbarRef}
        isScrolling={(isScrolling) => {
          this.scrollHandling();
        }}
        scrollerRef={(el) => {
          //Add scroll event listener because horizontal scroll event currently is not supported in the library
          if (!this.scrollbarRef && el) {
            el?.addEventListener("scroll", this.scrollHandling);
          }
          this.scrollbarRef = el;
        }}
        totalCount={this.getTotalCount()}
        itemContent={(i) => this.renderRowItem(i)}
        initialItemCount={this.getTotalCount()}
      />
    );
  };
  renderBody = () => {
    const { scrollable } = this.props;
    const grid = this.renderGrid();
    if (scrollable === false) {
      const rows = [];
      for (let i = 0; i < this.getTotalCount(); i++) {
        rows.push(this.renderRowItem(i));
      }
      return <div className={`${styles.nptTableScrollArea} w-100`}>{rows}</div>;
    }
    return (
      <PerfectScrollbar
        containerRef={(ref) => (this.scrollbarRef = ref)}
        options={{
          scrollXMarginOffset: 2,
        }}
        className={`${styles.nptTableScrollArea} w-100`}
        onScrollX={this.onScrollX}
        onScrollY={this.onScrollY}
        ref={(ref) => ref?.forceUpdate()}
      >
        {grid}
      </PerfectScrollbar>
    );
  };
  render() {
    /**TODO: FIXME? */
    if (
      this.scrollbarRef &&
      this.scrollbarRef.scrollTop !== this.props.scrollTop
    ) {
      this.scrollbarRef.scrollTop = this.props.scrollTop;
    }
    let grid = null;
    grid = this.renderGrid();
    const scrollHandlers = this.props.scrollable !== false ? undefined : [];
    const options: any = {
      scrollXMarginOffset: 2,
    };
    if (this.props.scrollable === false) {
      options.handlers = [];
    }
    return (
      <div className="d-flex flex-row flex-fill overflow-hidden">
        <TableDropConnector
          dropOptions={this.props.dropOptions}
          resolveDrop={this.props.resolveDrop}
        >
          {this.renderBody()}
        </TableDropConnector>
      </div>
    );
  }
}

/** Table drop function connector */
interface TableDropConnectorProps {
  dropOptions: TableDropOptions | null;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const TableDropConnector: React.FunctionComponent<TableDropConnectorProps> =
  React.memo((props) => {
    if (
      !props.dropOptions ||
      !props.dropOptions.table ||
      !props.dropOptions.resolveDropFunctionId
    ) {
      return <>{props.children}</>;
    }
    return (
      <DropTable
        dropOptions={props.dropOptions}
        resolveDrop={props.resolveDrop}
      >
        {props.children}
      </DropTable>
    );
  });
interface DropTableProps {
  dropOptions: TableDropOptions;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const DropTable: React.FunctionComponent<DropTableProps> = React.memo(
  (props) => {
    const [collectedProps, drop] = useDrop(() => ({
      accept: props.dropOptions.accepts,
      drop: async (item) => {
        props.resolveDrop(
          item,
          {},
          props.dropOptions.resolveDropFunctionId as string
        );
      },
      collect: (monitor) => {
        return {
          hover: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }));
    let className = "w-100 h-100 d-flex flex-row ";
    if (collectedProps.hover && collectedProps.canDrop) {
      className += ` ${styles.canDrop}`;
    }
    return (
      <div ref={drop} className={className}>
        {props.children}
      </div>
    );
  }
);

/** Single row component */
interface RowProps {
  column?: ColumnTemplateMap;
  style?: React.CSSProperties;
  row: RowData;
  autoresize?: boolean;
  rowIdx: number;
  isOdd: boolean;
  contextPath: string;
  stylesheets: { [k: string]: string };
  columns: Column[];
  columnsWidths: number[] | null;
  selectedRows: { [k: string]: boolean };
  dragOptions: TableDragOptions | null;
  dropOptions: TableDropOptions | null;
  selectRow: (key: string) => void;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const Row: React.FunctionComponent<RowProps> = React.memo((props: RowProps) => {
  let className = `${styles.nptTableRow} d-flex flex-row`;
  if (props.row.data.__unavailable) {
    className += ` ${styles.unavailable}`;
    return (
      <div style={{ ...props.style, width: "100%" }} className={className}>
        <FormattedMessage
          id="NPT_TABLE_ROW_NOT_AVAILABLE"
          defaultMessage="Not available"
          description="Row data wasn't downloaded by any reason"
        />
      </div>
    );
  }
  if (props.isOdd) {
    className += ` ${styles.odd}`;
  }
  if (props.row.changed !== null) {
    className += ` ${styles.changed}`;
  }

  return (
    <div className="row-1" style={{ ...props.style }}>
      <DropRowConnector
        row={props.row}
        rowIdx={props.rowIdx}
        dropOptions={props.dropOptions}
        resolveDrop={props.resolveDrop}
      >
        <DragRowConnector
          row={props.row}
          rowIdx={props.rowIdx}
          dragOptions={props.dragOptions}
        >
          <div className={`w-100 h-100 ${className}`}>
            {props.columns.map((columnData: Column, idx: number) => (
              <Cell
                key={idx}
                autoresize={props.autoresize}
                style={
                  props.autoresize
                    ? {
                        flexGrow: 1,
                        flexShrink: 1,
                      }
                    : undefined
                }
                columnIdx={idx}
                columnTemplate={props.column?.[columnData.field]}
                row={props.row}
                rowIdx={props.rowIdx}
                contextPath={props.contextPath}
                stylesheets={props.stylesheets}
                column={columnData}
                columnsWidths={props.columnsWidths}
                selectedRows={props.selectedRows}
                dropOptions={props.dropOptions}
                dragOptions={props.dragOptions}
                selectRow={props.selectRow}
                resolveDrop={props.resolveDrop}
              />
            ))}
          </div>
        </DragRowConnector>
      </DropRowConnector>
    </div>
  );
});

/** Row drop function connector */
interface DropRowConnectorProps {
  row: RowData;
  rowIdx: number;
  dropOptions: TableDropOptions | null;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const DropRowConnector: React.FunctionComponent<DropRowConnectorProps> =
  React.memo((props) => {
    if (
      !props.dropOptions ||
      !props.dropOptions.row ||
      props.dropOptions.table ||
      !props.dropOptions.resolveDropFunctionId
    ) {
      return <>{props.children}</>;
    }
    return (
      <DropRow
        row={props.row}
        rowIdx={props.rowIdx}
        dropOptions={props.dropOptions}
        resolveDrop={props.resolveDrop}
      >
        {props.children}
      </DropRow>
    );
  });
interface DropRowProps {
  row: RowData;
  rowIdx: number;
  dropOptions: TableDropOptions;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const DropRow: React.FunctionComponent<DropRowProps> = React.memo((props) => {
  const [collectedProps, drop] = useDrop(
    () => ({
      accept: props.dropOptions.accepts,
      drop: async (item) => {
        props.resolveDrop(
          item,
          { row: props.row, rowIdx: props.rowIdx },
          props.dropOptions.resolveDropFunctionId as string
        );
      },
      collect: (monitor) => {
        return {
          hover: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props.rowIdx]
  );
  let className = "w-100 h-100 d-flex flex-row ";
  if (collectedProps.hover && collectedProps.canDrop) {
    className += ` ${styles.canDrop}`;
  }

  return (
    <div ref={drop} className={className}>
      {props.children}
    </div>
  );
});

/** Row drag function connector */
interface DragRowConnectorProps {
  row: RowData;
  rowIdx: number;
  dragOptions: TableDragOptions | null;
}
const DragRowConnector: React.FunctionComponent<DragRowConnectorProps> =
  React.memo((props) => {
    if (!props.dragOptions || !props.dragOptions.row) {
      return <>{props.children}</>;
    }
    return (
      <DragRow
        row={props.row}
        rowIdx={props.rowIdx}
        dragOptions={props.dragOptions}
      >
        {props.children}
      </DragRow>
    );
  });
interface DragRowProps {
  row: RowData;
  rowIdx: number;
  dragOptions: TableDragOptions;
}
const DragRow: React.FunctionComponent<DragRowProps> = React.memo((props) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: props.dragOptions.type,
    item: (monitor) => {
      if (props.dragOptions.collectFunctionId) {
        const func = retrieveFunction(props.dragOptions.collectFunctionId);
        try {
          return func({ row: props.row, rowIdx: props.rowIdx });
        } catch (e) {
          console.error("Failure of the row data collection function", e);
        }
      }
      return props.row;
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));
  let className = `w-100 h-100 d-flex flex-row ${styles.draggable}`;
  if (collected.isDragging) {
    className += ` ${styles.isDragging}`;
  }
  return (
    <div ref={drag} className={className}>
      {props.children}
    </div>
  );
});

/** Single cell component */
interface CellProps {
  columnTemplate?: FC;
  columnIdx: number;
  row: RowData;
  autoresize?: boolean;
  rowIdx: number;
  style?: CSSProperties;
  contextPath: string;
  stylesheets: { [k: string]: string };
  column: Column;
  columnsWidths: number[] | null;
  selectedRows: { [k: string]: boolean };
  dropOptions: TableDropOptions | null;
  dragOptions: TableDragOptions | null;
  selectRow: (key: string) => void;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const Cell: React.FunctionComponent<CellProps> = React.memo((props) => {
  let [minWidth, setMinWidth] = React.useState(
    props.columnsWidths
      ? props.columnsWidths[props.columnIdx]
      : props.column.width
  );
  React.useEffect(() => {
    let nextMinWidth = props.column.width;
    if (props.columnsWidths != null) {
      nextMinWidth = props.columnsWidths[props.columnIdx];
    }
    setMinWidth(nextMinWidth);
  }, [props.column.width, props.columnsWidths, props.columnIdx]);

  if (props.column.hidden) {
    return null;
  }
  const cellInlineStyle =
    props.stylesheets &&
    props.row.classes &&
    props.row.classes[props.column.field]
      ? props.stylesheets[props.row.classes[props.column.field]]
      : null;
  const cellClass = !props.column.dataFormat ? "p-2" : "p-0";
  const cellStyle =
    !props.column.dataFormat && cellInlineStyle
      ? parseStyles(cellInlineStyle)
      : null;
  const cellWidth = minWidth || COLUMN_MIN_WIDTH;

  const cell = getRowValue(props.row, props.column.field);

  const widthStyle = props.autoresize
    ? { flexBasis: cellWidth }
    : { minWidth: cellWidth };
  return (
    <div
      style={{ ...cellStyle, ...widthStyle, ...props.style }}
      className={`${styles.nptTableCell}`}
    >
      <DropCellConnector
        cell={cell}
        row={props.row}
        rowIdx={props.rowIdx}
        columnIdx={props.columnIdx}
        column={props.column.field}
        dropOptions={props.dropOptions}
        resolveDrop={props.resolveDrop}
      >
        <DragCellConnector
          cell={cell}
          row={props.row}
          rowIdx={props.rowIdx}
          column={props.column.field}
          columnIdx={props.columnIdx}
          dragOptions={props.dragOptions}
        >
          <div
            className={`w-100 h-100 d-flex flex-row position-relative ${styles.nptTableCellData} ${cellClass}`}
          >
            <CellData
              columnTemplate={props.columnTemplate}
              row={props.row}
              cell={cell}
              contextPath={props.contextPath}
              column={props.column}
              rowIdx={props.rowIdx}
              columnIdx={props.columnIdx}
              field={props.column.field}
              selectedRows={props.selectedRows}
              selectRow={props.selectRow}
            />
          </div>
        </DragCellConnector>
      </DropCellConnector>
    </div>
  );
});

/** Cell drop function connector */
interface DropCellConnectorProps {
  cell: any;
  row: RowData;
  rowIdx: number;
  column: string;
  columnIdx: number;
  dropOptions: TableDropOptions | null;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const DropCellConnector: React.FunctionComponent<DropCellConnectorProps> =
  React.memo((props) => {
    if (
      !props.dropOptions ||
      props.dropOptions.row ||
      props.dropOptions.table ||
      !props.dropOptions.columns ||
      props.dropOptions.columns.indexOf(props.column) === -1 ||
      !props.dropOptions.resolveDropFunctionId
    ) {
      return <>{props.children}</>;
    }
    return (
      <DropCell
        cell={props.cell}
        row={props.row}
        rowIdx={props.rowIdx}
        column={props.column}
        columnIdx={props.columnIdx}
        dropOptions={props.dropOptions}
        resolveDrop={props.resolveDrop}
      >
        {props.children}
      </DropCell>
    );
  });
interface DropCellProps {
  cell: any;
  row: RowData;
  rowIdx: number;
  column: string;
  columnIdx: number;
  dropOptions: TableDropOptions;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const DropCell: React.FunctionComponent<DropCellProps> = React.memo((props) => {
  const [collectedProps, drop] = useDrop(() => ({
    accept: props.dropOptions.accepts,
    drop: async (item) => {
      props.resolveDrop(
        item,
        {
          cell: props.cell,
          row: props.row,
          rowIdx: props.rowIdx,
          column: props.column,
          columnIdx: props.columnIdx,
        },
        props.dropOptions.resolveDropFunctionId as string
      );
    },
    collect: (monitor) => {
      return {
        hover: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));
  let className = "w-100 h-100 d-flex flex-row ";
  if (collectedProps.hover && collectedProps.canDrop) {
    className += ` ${styles.canDrop}`;
  }
  return (
    <div ref={drop} className={className}>
      {props.children}
    </div>
  );
});

/** Row drag function connector */
interface DragCellConnectorProps {
  cell: any;
  row: RowData;
  rowIdx: number;
  column: string;
  columnIdx: number;
  dragOptions: TableDragOptions | null;
}
const DragCellConnector: React.FunctionComponent<DragCellConnectorProps> =
  React.memo((props) => {
    if (
      !props.dragOptions ||
      props.dragOptions.row ||
      !props.dragOptions.columns ||
      props.dragOptions.columns.indexOf(props.column) === -1
    ) {
      return <>{props.children}</>;
    }
    return (
      <DragCell
        cell={props.cell}
        row={props.row}
        rowIdx={props.rowIdx}
        column={props.column}
        columnIdx={props.columnIdx}
        dragOptions={props.dragOptions}
      >
        {props.children}
      </DragCell>
    );
  });

interface DragCellProps {
  cell: any;
  row: RowData;
  rowIdx: number;
  column: string;
  columnIdx: number;
  dragOptions: TableDragOptions;
}
const DragCell: React.FunctionComponent<DragCellProps> = React.memo((props) => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: props.dragOptions.type,
    item: (monitor) => {
      if (props.dragOptions.collectFunctionId) {
        const func = retrieveFunction(props.dragOptions.collectFunctionId);
        try {
          return func({
            cell: props.cell,
            row: props.row,
            rowIdx: props.rowIdx,
            column: props.column,
            columnIdx: props.columnIdx,
          });
        } catch (e) {
          console.error("Failure of the cell data collection function", e);
        }
      }
      return props.row;
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));
  let className = `w-100 h-100 d-flex flex-row ${styles.draggable}`;
  if (collected.isDragging) {
    className += ` ${styles.isDragging}`;
  }
  return (
    <div ref={drag} className={className}>
      {props.children}
    </div>
  );
});

/** Cell data formatter component */
interface CellDataProps {
  rowIdx: number;
  columnIdx: number;
  field: string;
  row: RowData;
  cell: any;
  columnTemplate?: FC<any>;
  contextPath: string;
  column: Column;
  selectedRows: { [k: string]: boolean };
  selectRow: (key: string) => void;
}
const CellData: React.FunctionComponent<CellDataProps> = React.memo((props) => {
  let cell = props.cell;
  /* Back compatibility */
  const { columnTemplate } = props;
  if (columnTemplate) {
    const ColumntTemplateComponent = columnTemplate;
    return <ColumntTemplateComponent data={props} />;
  }

  if (props.column.dataFormat) {
    // console.warn(
    //   "column.dataFormat is deprecated, please use standart methods",
    //   props.column,
    //   cell,
    //   props.row
    // );
    return (
      <CommonCellData
        cell={(props.column.dataFormat as any)(cell, props.row)}
      />
    );
  }
  if (props.column.fileRef) {
    const ref = getRowValue(props.row, props.column.fileRef);
    return (
      <FileRefCellData
        cell={cell}
        contextPath={props.contextPath}
        reference={ref}
      />
    );
  }
  if (props.column.format === "list") {
    return <ListCellData cell={cell} />;
  }
  /**Prevent error on object cell */
  if (cell !== null && typeof cell == "object") {
    cell = JSON.stringify(cell);
  }
  if (props.column.format === "selectColumn") {
    /*On select cell field value used to define select type */
    return (
      <SelectCellData
        row={props.row}
        type={props.column.field}
        selected={
          props.row.data.key
            ? props.selectedRows[props.row.data.key] || false
            : false
        }
        selectRow={props.selectRow}
      />
    );
  }
  if (props.column.subjectRef) {
    const ref = getRowValue(props.row, props.column.subjectRef);
    return (
      <SubjectRefCellData
        cell={cell}
        contextPath={props.contextPath}
        reference={ref}
      />
    );
  }
  if (props.column.format === "boolean") {
    return <BooleanCellData cell={cell} />;
  }
  if (props.column.format === "date") {
    return <DateCellData cell={cell} />;
  }
  if (props.column.format === "dateTime") {
    return <DateTimeCellData cell={cell} />;
  }
  if (props.column.format === "html") {
    return <HtmlCellData cell={cell} />;
  }
  if (props.column.format === "currency") {
    return (
      <CurrencyCellData
        cell={cell}
        currencyName={(props.column as CurrencyColumn).currencyName}
        currencyCents={(props.column as CurrencyColumn).currencyCents}
      />
    );
  }
  return <CommonCellData cell={cell} />;
});

/** Common row cell */
interface CommonCellDataProps {
  cell: string[] | string | number | null;
}
const CommonCellData: React.FunctionComponent<CommonCellDataProps> = React.memo(
  (props) => {
    return <>{props.cell}</>;
  }
);

/** Cell that parse value as boolean */
const BooleanCellData: React.FunctionComponent<CommonCellDataProps> =
  React.memo((props) => {
    if (typeof props.cell === "undefined" || props.cell === null) {
      return null;
    }
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <input
          className={styles.nptTableBooleanCell}
          type="checkbox"
          checked={Boolean(props.cell)}
        />
      </div>
    );
  });

/** Cell that parse value as date (ignores time) */
const DateCellData: React.FunctionComponent<CommonCellDataProps> = React.memo(
  (props) => {
    return <>{valueToDateString(props.cell)}</>;
  }
);

/** Cell that parse value as date with time */
const DateTimeCellData: React.FunctionComponent<CommonCellDataProps> =
  React.memo((props) => {
    return <>{valueToDateTimeString(props.cell)}</>;
  });

/** Cell that parse value as html element */
const HtmlCellData: React.FunctionComponent<CommonCellDataProps> = React.memo(
  (props) => {
    return (
      <div
        style={{ whiteSpace: "break-spaces" }}
        dangerouslySetInnerHTML={{
          __html: props.cell ? props.cell.toString() : "",
        }}
      ></div>
    );
  }
);

/** Cell that parse value to display as currency */
interface CurrencyCellDataProps {
  cell: string[] | string | number | null;
  currencyName: string | null;
  currencyCents: string | null;
}
const CurrencyCellData: React.FunctionComponent<CurrencyCellDataProps> =
  React.memo((props) => {
    const [value, setValue] = React.useState<string | null>(null);
    React.useEffect(() => {
      if (!props.currencyName) {
        setValue(null);
        return;
      }

      let cell = props.cell;
      if (typeof cell == "string") {
        cell = parseFloat(cell);
      } else if (typeof cell != "number") {
        setValue(null);
        return;
      }

      if (!props.currencyCents) {
        cell = cell.toFixed(0);
        setValue(cell + " " + props.currencyName);
        return;
      }

      cell = cell.toFixed(2);
      const parts = cell.split(".");
      setValue(
        parts[0] +
          " " +
          props.currencyName +
          " " +
          parts[1] +
          " " +
          props.currencyCents
      );
    }, [props.currencyName, props.currencyCents, props.cell]);

    return <>{value}</>;
  });

/** Cell that handle array value */
const ListCellData: React.FunctionComponent<CommonCellDataProps> = React.memo(
  (props) => {
    let cell = props.cell;
    if (Array.isArray(cell)) {
      cell = cell.join(", ");
    }
    return <>{cell}</>;
  }
);

/** Select row cell */
interface SelectCellDataProps {
  row: RowData;
  type: string;
  selected: boolean;
  selectRow: (key: string) => void;
}
const SelectCellData: React.FunctionComponent<SelectCellDataProps> = React.memo(
  (props) => {
    const haveKey =
      typeof props.row.data.key != "undefined" && props.row.data.key != null;
    return (
      <div
        className={`${styles.nptTableSelectCell} w-100 d-flex flex-column p-2`}
      >
        <input
          type={props.type}
          disabled={!haveKey}
          checked={props.selected}
          onChange={props.selectRow.bind(null, props.row.data.key as string)}
        ></input>
        {/* {!haveKey && <div className="text-danger">
            <FormattedMessage
                id="TABLE_NO_KEY"
                defaultMessage="Haven't key"
                description="Row haven't any key to select" />
        </div>} */}
      </div>
    );
  }
);

/** Reference to subject cell */
interface SubjectRefCellDataProps {
  cell: string[] | string | number | null;
  contextPath: string;
  reference?: string[] | string | number | null;
}
const SubjectRefCellData: React.FunctionComponent<SubjectRefCellDataProps> =
  React.memo((props) => {
    if (Array.isArray(props.cell) && Array.isArray(props.reference)) {
      const elements: JSX.Element[] = [];
      for (let i = 0; i < props.cell.length; ++i) {
        if (i !== 0) {
          elements.push(<span key={`s-${i}`}> / </span>);
        }
        elements.push(
          <Link
            key={`a-${i}`}
            to={`${props.contextPath}objectcard/${props.reference[i]}`}
          >
            {props.cell[i]}
          </Link>
        );
      }
      return <>{elements}</>;
    }
    return (
      <Link to={`${props.contextPath}objectcard/${props.reference}`}>
        {props.cell}
      </Link>
    );
  });

/** Reference to file cell */
interface FileRefCellDataProps {
  cell: { [k: string]: string } | null;
  contextPath: string;
  reference?: string[] | string | number | null;
}
const FileRefCellData: React.FunctionComponent<FileRefCellDataProps> =
  React.memo((props) => {
    if (!props.cell || !props.cell.$sha1) {
      return <span>--</span>;
    }
    const filename =
      props.cell.$originalName || props.cell.$label || props.cell.$sha1;
    return (
      <a
        href={`${props.contextPath}rest/file/download/${props.cell.$sha1}`}
        download={filename}
      >
        <i className="fa fa-download" aria-hidden="true"></i>
      </a>
    );
  });
