import * as React from "react";
import { FetchError } from "../../types/error";
import {
  Column,
  ColumnTemplateMap,
  FilterData,
  FilterInfo,
  GantDateLimits,
  GantGroup,
  GantPlannedGroups,
  RowData,
  SelectType,
  SortInfo,
  TableDragOptions,
  TableDropOptions,
  TableGantData,
  TableGantOptions,
} from "../../types/table";
import { GantProps } from "./Gant";
import { COLUMN_MIN_WIDTH } from "../../constants/table";
import { getColumnsWidths } from "../../services/table";
import { bindEvent, unbindEvent } from "../../services/event";

import styles from "./Table.module.css";
import TableToolbar from "../table/TableToolbar";
import TablePagination from "../table/TablePagination";
import TableHeader from "../table/TableHeader";
import TableBody from "./TableBody";
import TableGantHeader from "./TableGantHeader";
import TableGantBody from "./TableGantBody";

/**Properties of Table container */
export interface TableContainerProps extends GantProps {
  /**Width of table for column width calculating */
  width: number;
  /**Height of table */
  height: number;
  /**Context path of application (needed for link cells)*/
  contextPath: string;
  /**List of selected dynamic columns */
  selectedDynamicColumns: Column[] | null;
}
export interface TableContainerState {
  /**Current horizontal scroll position of table*/
  tableScrollLeft: number;
  /**Current horizontal scroll position of gant diagram*/
  gantScrollLeft: number;
  /**Current vertical scroll position*/
  scrollTop: number;
  /**Current resizing state */
  resizeInfo: {
    /**Flag if resizing is active */
    active: boolean;
    /**Flag if resizing was at least once performed on this table */
    applied: boolean;
    /**Dynamic columns width */
    columnsWidths: number[] | null;
    /**Index of left column that is resizing */
    leftColIdx: number;
    /**Index of right column that is resizing */
    rightColIdx: number;
    /**Start value of x-coordinate on grabbing */
    startX: number;
  };
}

/**Properties of special sscroll synchronizing function */
export interface OnScrollParams {
  scrollLeft?: number;
  scrollTop?: number;
}

/********************************
 *   Container Table Component  *
 ********************************/
export default class TableContainer extends React.Component<
  TableContainerProps,
  TableContainerState
> {
  private columns: Column[];
  private columnsWidths: number[] | null = null;
  private minWidth: number;

  constructor(props: TableContainerProps) {
    super(props);

    this.state = {
      tableScrollLeft: 0,
      gantScrollLeft: 0,
      scrollTop: 0,
      resizeInfo: {
        active: false,
        applied: false,
        columnsWidths: null,
        leftColIdx: -1,
        rightColIdx: -1,
        startX: 0,
      },
    };

    this.columns = this.getColumns(
      this.getDynamicColumns(),
      props.selectType,
      props.dynamicHiddenColumns
    );
    this.minWidth = this.getMinWidth(this.columns);
    this.columnsWidths = getColumnsWidths({
      columns: this.columns,
      width: this.getTableWidth(props.width),
    });

    this.onTableScroll = this.onTableScroll.bind(this);
    this.onGantScroll = this.onGantScroll.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.grabResizer = this.grabResizer.bind(this);
  }

  getDynamicColumns(props = this.props) {
    return props.selectedDynamicColumns || props.columns;
  }

  getColumns(
    columns: Column[],
    selectType: SelectType | null,
    dynamicHiddenColumns: { [k: string]: boolean }
  ) {
    const preparedColumns = columns.slice();
    for (let i = 0; i < preparedColumns.length; ++i) {
      if (!dynamicHiddenColumns[preparedColumns[i].field]) {
        continue;
      }
      preparedColumns[i] = { ...preparedColumns[i], hidden: true };
    }
    if (selectType != null) {
      preparedColumns.unshift({
        key: false,
        autoGenerated: true,
        name: "",
        format: "selectColumn",
        filterType: null,
        originalWidth: 50,
        width: 50,
        field: selectType,
      });
    }
    return preparedColumns;
  }

  getMinWidth(columns: Column[]) {
    let minWidth = 0;
    for (let column of columns) {
      if (column.hidden) {
        continue;
      }
      let columnWidth = COLUMN_MIN_WIDTH;
      if (column.width && column.width > columnWidth) {
        columnWidth = column.width;
      }
      minWidth += columnWidth;
    }
    return minWidth;
  }

  getTableWidth(propsWidth: number): number {
    return this.minWidth > propsWidth ? this.minWidth : propsWidth;
  }

  onTableScroll({ scrollLeft, scrollTop }: OnScrollParams) {
    const isScrollLeft = typeof scrollLeft == "number";
    const isScrollTop = typeof scrollTop == "number";
    if (
      (!isScrollLeft && !isScrollTop) ||
      (scrollLeft === this.state.tableScrollLeft &&
        scrollTop === this.state.scrollTop)
    ) {
      return;
    }
    const nextState = { ...this.state };
    if (isScrollLeft) {
      nextState.tableScrollLeft = scrollLeft as number;
    }
    if (isScrollTop) {
      nextState.scrollTop = scrollTop as number;
    }
    this.setState(nextState);
  }

  onGantScroll({ scrollLeft, scrollTop }: OnScrollParams) {
    const isScrollLeft = typeof scrollLeft == "number";
    const isScrollTop = typeof scrollTop == "number";
    if (
      (!isScrollLeft && !isScrollTop) ||
      (scrollLeft === this.state.gantScrollLeft &&
        scrollTop === this.state.scrollTop)
    ) {
      return;
    }
    const nextState = { ...this.state };
    if (isScrollLeft) {
      nextState.gantScrollLeft = scrollLeft as number;
    }
    if (isScrollTop) {
      nextState.scrollTop = scrollTop as number;
    }
    this.setState(nextState);
  }

  resetResizeInfo() {
    this.setState({
      ...this.state,
      resizeInfo: {
        active: false,
        applied: false,
        columnsWidths: null,
        leftColIdx: -1,
        rightColIdx: -1,
        startX: 0,
      },
    });
  }

  grabResizer(leftIdx: number, rightIdx: number, startX: number) {
    const nextState = { ...this.state };
    const resizeInfo = { ...nextState.resizeInfo };
    nextState.resizeInfo = resizeInfo;
    if (resizeInfo.active) {
      this.releaseResizer();
      return;
    }
    resizeInfo.active = true;
    if (this.columnsWidths) {
      resizeInfo.columnsWidths = this.columnsWidths;
    } else {
      resizeInfo.columnsWidths = [];
      for (let column of this.columns) {
        resizeInfo.columnsWidths.push(
          typeof column.width !== "undefined" ? column.width : COLUMN_MIN_WIDTH
        );
      }
    }
    resizeInfo.leftColIdx = leftIdx;
    resizeInfo.rightColIdx = rightIdx;
    resizeInfo.startX = startX;
    this.setState(nextState);
  }

  moveResizer(pageX: number) {
    if (!this.state.resizeInfo.active) {
      return;
    }
    let columnsWidths = this.columnsWidths;
    if (!columnsWidths) {
      return;
    }
    columnsWidths = columnsWidths.slice();
    let difference = pageX - this.state.resizeInfo.startX;
    columnsWidths[this.state.resizeInfo.leftColIdx] += difference;
    if (columnsWidths[this.state.resizeInfo.leftColIdx] < COLUMN_MIN_WIDTH) {
      difference +=
        COLUMN_MIN_WIDTH - columnsWidths[this.state.resizeInfo.leftColIdx];
      columnsWidths[this.state.resizeInfo.leftColIdx] = COLUMN_MIN_WIDTH;
    }
    for (
      let i = this.state.resizeInfo.leftColIdx + 1;
      i < this.columns.length;
      ++i
    ) {
      const column = this.columns[i];
      if (column.hidden) {
        continue;
      }
      let columnWidth = columnsWidths[i] - difference;
      if (columnWidth >= COLUMN_MIN_WIDTH) {
        difference = 0;
        columnsWidths[i] = columnWidth;
        break;
      }
      difference = COLUMN_MIN_WIDTH - columnWidth;
      columnWidth = COLUMN_MIN_WIDTH;
      columnsWidths[i] = columnWidth;
    }
    /**Reduce final column width by remaining difference */
    columnsWidths[this.state.resizeInfo.leftColIdx] -= difference;

    const nextState = { ...this.state };
    const resizeInfo = {
      ...nextState.resizeInfo,
      columnsWidths: columnsWidths,
    };
    nextState.resizeInfo = resizeInfo;
    this.setState(nextState);
  }

  releaseResizer() {
    if (!this.state.resizeInfo.active) {
      return;
    }
    const nextState = { ...this.state };
    const resizeInfo = { ...nextState.resizeInfo };
    nextState.resizeInfo = resizeInfo;
    this.columnsWidths = resizeInfo.columnsWidths;
    resizeInfo.active = false;
    resizeInfo.columnsWidths = null;
    resizeInfo.leftColIdx = -1;
    resizeInfo.rightColIdx = -1;
    resizeInfo.startX = 0;
    this.setState(nextState);
  }

  handleMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.moveResizer(event.pageX);
  }

  handleMouseUp(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.releaseResizer();
  }

  componentDidMount() {
    bindEvent({
      event: `mousemove.npt.table.${this.props.tableId}`,
      handler: this.handleMouseMove,
    });
    bindEvent({
      event: `mouseup.npt.table.${this.props.tableId}`,
      handler: this.handleMouseUp,
    });
  }

  componentWillReceiveProps(nextProps: TableContainerProps) {
    if (
      this.getDynamicColumns() !== this.getDynamicColumns(nextProps) ||
      this.props.selectType !== nextProps.selectType
    ) {
      this.columns = this.getColumns(
        this.getDynamicColumns(nextProps),
        nextProps.selectType,
        nextProps.dynamicHiddenColumns
      );
      this.minWidth = this.getMinWidth(this.columns);
      this.columnsWidths = getColumnsWidths({
        columns: this.columns,
        width: this.getTableWidth(nextProps.width),
      });
      this.resetResizeInfo();
      this.forceUpdate();
      return;
    }
    if (
      this.props.width !== nextProps.width &&
      !this.state.resizeInfo.applied
    ) {
      this.columnsWidths = getColumnsWidths({
        columns: this.columns,
        width: this.getTableWidth(nextProps.width),
      });
      this.resetResizeInfo();
      this.forceUpdate();
      return;
    }
  }

  componentWillUnmount() {
    unbindEvent({ event: `mousemove.npt.table.${this.props.tableId}` });
    unbindEvent({ event: `mouseup.npt.table.${this.props.tableId}` });
  }
  renderToolbar = () => {
    const { toolbar } = this.props;

    if (!toolbar) {
      return null;
    }

    if (toolbar && !Array.isArray(toolbar)) {
      const ToolbarComponent = toolbar;
      return <ToolbarComponent />;
    }

    return (
      <TableToolbar
        tableId={this.props.tableId}
        loadingData={Boolean(this.props.loadingData)}
        parameters={this.props.parameters}
        fields={this.props.fields}
        filterChanges={this.props.filterChanges}
        gantOptions={this.props.gantOptions}
        gantData={this.props.gantData}
        reports={this.props.reports}
        toolbar={toolbar}
        userAcl={this.props.userAcl}
        totalRowsLength={this.props.totalRowsLength}
        hidden={this.props.toolbarHidden}
        hiddenItems={this.props.toolbarHiddenItems}
        columns={this.getDynamicColumns()}
        downloadReport={this.props.downloadReport}
        buttonClick={this.props.buttonClick}
        changeField={this.props.changeField}
        changeViewType={this.props.changeViewType}
        changeGroup={this.props.changeGroup}
        confirmFilterChanges={this.props.confirmFilterChanges}
        denyFilterChanges={this.props.denyFilterChanges}
        saveTable={this.props.saveTable}
      />
    );
  };
  getMainTableWidth = () => {
    let mainTableWidth = this.props.width;
    if (this.props.gantOptions !== null) {
      mainTableWidth *= this.props.gantOptions.headersWidth;
    }
    return mainTableWidth;
  };
  renderBody = () => {
    const { body } = this.props;
    if (!body) {
      const tableWidth = this.getTableWidth(this.props.width);
      const columnsWidths =
        this.state.resizeInfo.columnsWidths || this.columnsWidths;
      const pageRows = this.props.pageRows;
      let mainTableWidth = this.getMainTableWidth();
      // let mainTableWidth = this.props.width;
      let className = styles.nptTableContainer;
      if (this.props.gantOptions !== null) {
        className += ` ${styles.haveGant}`;
        // mainTableWidth *= this.props.gantOptions.headersWidth;
      }
      const gantWidth = this.props.width - mainTableWidth;
      const gantFullWidth = Math.max(
        gantWidth,
        this.props.gantData.gantMinWidth
      );
      let style: React.CSSProperties = {
        width: this.props.width,
        height: "auto",
      };
      if (this.props.height >= 0) {
        style.height = this.props.height;
        // style.height = this.props.height;
      }
      return (
        <TableBodyContainer
          column={this.props.column}
          contextPath={this.props.contextPath}
          width={this.props.width}
          scrollable={this.props.scrollable}
          errorData={this.props.errorData}
          loadingData={this.props.loadingData}
          tableWidth={mainTableWidth}
          tableFullWidth={tableWidth}
          tableScrollLeft={this.state.tableScrollLeft}
          gantWidth={gantWidth}
          gantFullWidth={gantFullWidth}
          gantScrollLeft={this.state.gantScrollLeft}
          scrollTop={this.state.scrollTop}
          stylesheets={this.props.stylesheets}
          columns={this.columns}
          columnsWidths={columnsWidths}
          pageRows={pageRows}
          gantOptions={this.props.gantOptions}
          gantData={this.props.gantData}
          rowByIdx={this.props.rowByIdx}
          selectedRows={this.props.selectedRows}
          dragOptions={this.props.dragOptions}
          dropOptions={this.props.dropOptions}
          selectRow={this.props.selectRow}
          onTableScroll={this.onTableScroll}
          onGantScroll={this.onGantScroll}
          onGantElementChange={this.props.changeGantElement}
          resolveDrop={this.props.resolveDrop}
        />
      );
    }
    const BodyComponent = body;
    return <BodyComponent />;
  };
  renderFooter = () => {
    const { footer } = this.props;
    if (!footer) {
      return (
        <TablePagination
          loading={this.props.loading}
          pageable={this.props.pageable}
          page={this.props.page}
          pageSize={this.props.pageSize}
          pageSelection={this.props.pageSelection}
          totalRowsLength={this.props.totalRowsLength}
          changePageSize={this.props.changePageSize}
          changePage={this.props.changePage}
        />
      );
    }
    const FooterComponent = footer;
    return <FooterComponent />;
  };
  render() {
    const tableWidth = this.getTableWidth(this.props.width);
    const columnsWidths =
      this.state.resizeInfo.columnsWidths || this.columnsWidths;
    const pageRows = this.props.pageRows;
    let mainTableWidth = this.props.width;
    let className = styles.nptTableContainer;
    if (this.props.gantOptions !== null) {
      className += ` ${styles.haveGant}`;
      mainTableWidth *= this.props.gantOptions.headersWidth;
    }
    const gantWidth = this.props.width - mainTableWidth;
    const gantFullWidth = Math.max(gantWidth, this.props.gantData.gantMinWidth);
    let style: React.CSSProperties = {
      width: this.props.width,
      height: "auto",
    };
    if (this.props.height >= 0) {
      style.height = "100%";
    }

    return (
      <div className={className} style={{ ...style, width: "100%" }}>
        {this.renderToolbar()}
        <TableHeaderContainer
          tableId={this.props.tableId}
          width={this.props.width}
          tableWidth={mainTableWidth}
          gantOptions={this.props.gantOptions}
          tableFullWidth={tableWidth}
          tableScrollLeft={this.state.tableScrollLeft}
          gantWidth={gantWidth}
          gantFullWidth={gantFullWidth}
          gantScrollLeft={this.state.gantScrollLeft}
          columns={this.columns}
          columnsWidths={columnsWidths}
          selectedRowsLength={this.props.selectedRowsLength}
          pageRows={pageRows}
          sortInfo={this.props.sortInfo}
          filterInfo={this.props.filterInfo}
          gantData={this.props.gantData}
          selectAll={this.props.selectAll}
          sortByColumn={this.props.sortByColumn}
          filterByColumn={this.props.filterByColumn}
          grabResizer={this.grabResizer}
          fetchColumnSelection={this.props.fetchColumnSelection}
          fetchColumnRange={this.props.fetchColumnRange}
          openTableSortOptions={this.props.openTableSortOptions}
          openTableDynamicColumns={this.props.openTableDynamicColumns}
        />
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    );
  }
}

interface TableHeaderContainerProps {
  tableId: string;
  gantOptions: TableGantOptions | null;
  width: number;
  tableWidth: number;
  tableFullWidth: number;
  tableScrollLeft: number;
  gantWidth: number;
  gantFullWidth: number;
  gantScrollLeft: number;
  columns: Column[];
  columnsWidths: number[] | null;
  selectedRowsLength: number;
  pageRows: number[];
  sortInfo: SortInfo;
  filterInfo: FilterInfo;
  gantData: TableGantData;
  selectAll: () => void;
  sortByColumn: (field: string) => void;
  filterByColumn: (field: string, filterData: FilterData | null) => void;
  grabResizer: (leftIdx: number, rightIdx: number, startX: number) => void;
  fetchColumnSelection: (field: string, filterData: FilterData | null) => void;
  fetchColumnRange: (field: string, filterData: FilterData | null) => void;
  openTableSortOptions: () => void;
  openTableDynamicColumns: () => void;
}
const TableHeaderContainer: React.FunctionComponent<TableHeaderContainerProps> =
  React.memo((props: TableHeaderContainerProps) => {
    return (
      <div
        className={styles.nptTableHeaderContainer}
        style={{ width: props.width }}
      >
        <div
          className={styles.nptTableContainerColumn}
          style={{ width: props.tableWidth }}
        >
          <TableHeader
            tableId={props.tableId}
            tableWidth={props.tableFullWidth}
            scrollLeft={props.tableScrollLeft}
            columns={props.columns}
            columnsWidths={props.columnsWidths}
            selectedRowsLength={props.selectedRowsLength}
            pageRows={props.pageRows}
            sortInfo={props.sortInfo}
            filterInfo={props.filterInfo}
            selectAll={props.selectAll}
            sortByColumn={props.sortByColumn}
            filterByColumn={props.filterByColumn}
            grabResizer={props.grabResizer}
            fetchColumnSelection={props.fetchColumnSelection}
            fetchColumnRange={props.fetchColumnRange}
            openTableSortOptions={props.openTableSortOptions}
            openTableDynamicColumns={props.openTableDynamicColumns}
          />
        </div>

        <div
          className={styles.nptTableContainerColumn}
          style={{ width: props.gantWidth }}
        >
          <TableGantHeader
            gantWidth={props.gantFullWidth}
            scrollLeft={props.gantScrollLeft}
            displayScales={props.gantData.displayScales}
            cellsByScale={props.gantData.cellsByScale}
          />
        </div>
      </div>
    );
  });

interface TableBodyContainerProps {
  contextPath: string;
  width: number;
  column?: ColumnTemplateMap;
  scrollable?: boolean;
  errorData: null | FetchError;
  loadingData: boolean;
  tableWidth: number;
  tableFullWidth: number;
  tableScrollLeft: number;
  gantWidth: number;
  gantFullWidth: number;
  gantScrollLeft: number;
  scrollTop: number;
  stylesheets: { [k: string]: string };
  columns: Column[];
  columnsWidths: number[] | null;
  pageRows: number[];
  rowByIdx: { [rowIdx: number]: RowData };
  gantOptions: TableGantOptions | null;
  gantData: TableGantData;
  selectedRows: { [k: string]: boolean };
  dragOptions: TableDragOptions | null;
  dropOptions: TableDropOptions | null;
  selectRow: (key: string) => void;
  onTableScroll: (params: OnScrollParams) => void;
  onGantScroll: (params: OnScrollParams) => void;
  onGantElementChange: (
    rowIndex: number,
    itemIndex: number,
    newDate: GantDateLimits,
    isPlanned: boolean
  ) => void;
  resolveDrop: (
    collectedData: any,
    dropInfo: {
      cell?: any;
      row?: RowData;
      rowIdx?: number;
      column?: string;
      columnIdx?: number;
    },
    resolveDropFunctionId: string
  ) => void;
}
const TableBodyContainer: React.FunctionComponent<TableBodyContainerProps> =
  React.memo((props: TableBodyContainerProps) => {
    const [chosenGroupsMap, setChosenGroupsMap] = React.useState<{
      [k: string]: GantGroup;
    }>({});
    const [plannedGroupsMap, setPlannedGroupsMap] =
      React.useState<GantPlannedGroups>(
        Object.assign({}, props.gantOptions?.gantPlannedGroups)
      );
    React.useEffect(() => {
      const nextChosenGroupsMap = Object.assign({}, chosenGroupsMap);
      if (props.gantOptions?.gantGroups) {
        for (let group of props.gantOptions.gantGroups) {
          nextChosenGroupsMap[group.id] = group;
        }
      }
      setChosenGroupsMap(nextChosenGroupsMap);
    }, [props.gantOptions?.gantGroups]);
    React.useEffect(() => {
      const nextPlannedGroupsMap = Object.assign({}, plannedGroupsMap);
      setPlannedGroupsMap(nextPlannedGroupsMap);
    }, [props.gantOptions?.gantPlannedGroups]);

    const displayedScale =
      props.gantData.scale === "half-day" ? "day" : props.gantData.scale;
    let style: React.CSSProperties = { width: props.width };
    let bodyHeight: number | undefined = undefined;
    if (props.scrollable === false) {
      bodyHeight = props.pageRows.length * 80 || 50;
      style.height = bodyHeight;
    }

    return (
      <div
        className={styles.nptTableBodyContainer}
        style={{ ...style, width: "100%" }}
      >
        <div
          className={styles.nptTableContainerColumn}
          // style={{ width: "100%" }}
          style={{ width: props.tableWidth }}
        >
          <TableBody
            column={props.column}
            contextPath={props.contextPath}
            width={props.tableWidth}
            bodyHeight={bodyHeight}
            loadingData={Boolean(props.loadingData)}
            errorData={props.errorData}
            tableWidth={props.tableFullWidth}
            scrollLeft={props.tableScrollLeft}
            scrollTop={props.scrollTop}
            stylesheets={props.stylesheets}
            columns={props.columns}
            columnsWidths={props.columnsWidths}
            pageRows={props.pageRows}
            rowByIdx={props.rowByIdx}
            selectedRows={props.selectedRows}
            dragOptions={props.dragOptions}
            dropOptions={props.dropOptions}
            onScroll={props.onTableScroll}
            selectRow={props.selectRow}
            resolveDrop={props.resolveDrop}
          />
        </div>

        <div
          className={styles.nptTableContainerColumn}
          style={{ width: props.gantWidth }}
        >
          <TableGantBody
            width={props.gantWidth}
            bodyHeight={bodyHeight}
            gantWidth={props.gantFullWidth}
            loadingData={Boolean(props.loadingData)}
            errorData={props.errorData}
            scrollLeft={props.gantScrollLeft}
            scrollTop={props.scrollTop}
            pageRows={props.pageRows}
            rowByIdx={props.rowByIdx}
            selectedRows={props.selectedRows}
            viewType={props.gantData.viewType}
            dateLimits={props.gantData.dateLimits}
            gantChosenGroups={chosenGroupsMap}
            gantPlannedGroups={plannedGroupsMap}
            scale={props.gantData.scale}
            scaleCells={props.gantData.cellsByScale[displayedScale]}
            onScroll={props.onGantScroll}
            onElementChange={props.onGantElementChange}
          />
        </div>
      </div>
    );
  });
