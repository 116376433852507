import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import * as Redux from "redux";
import {
  faEnvelope,
  faDoorClosed,
  faHome,
  faKey,
  faPaintBrush,
  faSignOutAlt,
  faBars,
  faCode,
  faShare,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { connect } from "react-redux";
import { Nav, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Plugin from "../plugin/Plugin";
import { isLoggedInUser, isLoginStatus, MenuData } from "../../types/security";
import { CEditorTheme, NptTheme } from "../../constants/theme";
import { ThemeService } from "../../services/theme";

import { Logout, NavLink } from "./Menu";
import { DropDownMenu } from "./DropDownMenu";
import { CustomNavDropdown } from "./CustomNavDropdown";
import { OverflowDropDown } from "./OverflowDropDown";
import { buildParamsString } from "../../services/location";
import { SelectionState } from "../../types/selection";
import { copyTextToClipboard } from "../../services/clipboard";
import { Dispatch, RootState } from "../../store";
import { setupHomePage } from "../../model/menu";

interface CustomToggleProps {
  className?: string;
  children: any;
  onClick: (e: any) => void;
  toggle: () => void;
}
//  dropdown-toggle nav-link
export const ToggleWithoutArrow = React.forwardRef(
  (props: CustomToggleProps, ref: any) => (
    <a
      href="#"
      ref={ref}
      className={`  p-0 nav-link `}
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
        props.toggle();
      }}
    >
      {props.children}
    </a>
  )
);

export interface UserMenuProps {
  selection?: SelectionState;
  language: string;
  systemMenu?: MenuData;
  isSuperUser?: boolean;
  authorities?: string[];
  className?: string;
  extended?: boolean;
  mainMenu?: MenuData;
  title?: React.ReactNode;
  as?: any;
  badge?: any;
  setupHomePage: () => void;
  changePassword: () => void;
  logout?: () => void;
}

export interface UserMenuStates {
  show: boolean;
}

export class UserMenu extends React.Component<UserMenuProps, UserMenuStates> {
  constructor(props: UserMenuProps) {
    super(props);
    this.state = { show: false };
  }

  clickHandler = (e: any) => {
    const isMenuClicked = e.target.closest(".menuItemUser");
    const { show } = this.state;
    if (!isMenuClicked && show) {
      this.setState({ show: false });
    }
  };

  componentDidMount() {
    document.addEventListener("click", this.clickHandler);
  }

  componentWillUnmount() {
    document.addEventListener("click", this.clickHandler);
  }

  renderMenuItem(id: string, menu: MenuData) {
    const item = menu.itemById[id];
    if (typeof item == "undefined") {
      return null;
    }
    const title = (item.t && item.t[this.props.language]) || item.n || id;
    const ref = item.r || "";
    const icon = item.i ? (
      <i className={item.i + " mr-2"} aria-hidden="true" />
    ) : null;
    return (
      <NavLink to={ref} dropDown={true} key={id}>
        {icon}
        {title}
      </NavLink>
    );
  }

  renderSystemMenu() {
    const m = this.props.systemMenu;
    if (typeof m == "undefined" || typeof m.roots == "undefined") {
      return null;
    }
    return m.roots.map((id) => this.renderMenuItem(id, m));
  }

  renderUserMenuTitle() {
    const { title } = this.props;
    if (title) {
      return title;
    }
    return (
      <button className="btn btn-primary px-2">
        <i className="fa fa-ellipsis-v fa-lg text-white"></i>
      </button>
    );
  }

  renderPlainTitle(id: string, icon: IconDefinition, action?: Function) {
    return (
      <div onClick={() => action && action()}>
        <FontAwesomeIcon icon={icon} className="fa-lg fa-fw mr-2" />
        <FormattedMessage
          id={id}
          defaultMessage="Messages"
          description="Messages link"
        />
      </div>
    );
  }

  wrapLink(id: string, title: any, l: { exact?: boolean; to?: string }) {
    return (
      <CustomNavDropdown
        to={l.to}
        exact={l.exact}
        alignRight
        id={id}
        title={title}
      />
    );
  }
  themesTitle() {
    return (
      <div>
        <FontAwesomeIcon icon={faPaintBrush} className="fa-lg fa-fw mr-2" />
        <span className="">
          <FormattedMessage id={"THEME_TITLE"} defaultMessage="Themes" />
        </span>
      </div>
    );
  }
  renderMainMenuTitle() {
    return (
      <div>
        <FontAwesomeIcon
          key="ico"
          icon={faBars}
          size="lg"
          className="fa-lg fa-fw mr-2"
        />
        <span>
          <FormattedMessage id="MAIN_MENU" />
        </span>
      </div>
    );
  }
  renderSetupHome() {
    const { extended, mainMenu, language, setupHomePage } = this.props;
    if (!extended) {
      return (
        <NavLink to="#" dropDown={true}>
          <div onClick={() => this.props.setupHomePage()}>
            <FontAwesomeIcon icon={faHome} className="fa-lg fa-fw mr-2" />
            <FormattedMessage id={"USERMENU_SETUP_HOME_PAGE"} />
          </div>
        </NavLink>
      );
    }
    if (!mainMenu || !mainMenu.roots || mainMenu.roots.length === 0) {
      return null;
    }
    return (
      <DropDownMenu
        language={language}
        title={this.renderMainMenuTitle()}
        menu={mainMenu}
      />
    );
  }
  isActiveTheme(theme: NptTheme) {
    const themeObj = ThemeService.getTheme();
    return themeObj.platformTheme === theme;
  }
  getViewPath() {
    const path = window.location.pathname;
    if (path && path.indexOf("/view/") === 0) {
      return path.replace("/view", "");
    }
    return null;
  }
  checkRights = () => {
    const { isSuperUser, authorities } = this.props;
    if (isSuperUser) {
      return true;
    }
    if (authorities && authorities.includes("NPT_VIEW_EDITOR")) {
      return true;
    }
    return false;
  };
  renderGoToView(viewPath: string | null) {
    if (!viewPath) {
      return null;
    }
    const hasAccess = this.checkRights();

    if (!hasAccess) {
      return null;
    }
    return (
      <NavLink
        to={"#"}
        dropDown={true}
        onClick={() => {
          const viewPath = this.getViewPath();
          viewPath && window.open("/developer/vieweditor" + viewPath, "_blank");
        }}
      >
        <FontAwesomeIcon icon={faCode} className="fa-lg fa-fw mr-2" />
        <FormattedMessage id={"USERMENU_GO_TO_VIEW"} />
      </NavLink>
    );
  }
  renderShareLink = () => {
    return (
      <>
        <NavLink to="#" onClick={this.shareLink} dropDown={true}>
          <FontAwesomeIcon icon={faShareAlt} className="fa-lg fa-fw mr-2" />
          <FormattedMessage id={"USERMENU_SHARE"} />
        </NavLink>
        <Dropdown.Divider />
      </>
    );
  };
  shareLink = (e: any) => {
    e.preventDefault();
    const { selection } = this.props;

    let url = window.location.href;
    const viewPath = this.getViewPath();
    if (viewPath && selection?.info) {
      const origin = window.location.origin;
      const pathname = window.location.pathname;
      const params = buildParamsString(selection.info as any);
      url = `${origin}${pathname}`;
      if (params) {
        url += `?${params}`;
      }
    }
    copyTextToClipboard(url);
  };
  render() {
    const { extended, logout, as: asType, badge } = this.props;
    const { show } = this.state;

    const ToggleType = asType || undefined;
    const plugins = (window as any).__ui_plugins__ as { [P: string]: string };
    let messagesExist = plugins?.["messages"];
    const viewPath = this.getViewPath();
    return (
      <Nav.Item
        style={{ zIndex: 1000 }}
        className="menuItemUser npt-user-menu d-flex flex-column justify-content-center position-relative"
      >
        <OverflowDropDown
          root={true}
          badge={badge}
          className="nav-item pl-1"
          as={ToggleType}
          direction="down"
          id={"user-menu"}
          title={this.renderUserMenuTitle()}
          key={"user-menu"}
        >
          {/* <CustomNavDropdown className="nav-item pl-1  " as={ToggleType} alignRight id={'user-menu'} title={this.renderUserMenuTitle()} key={'user-menu'} > */}
          {messagesExist && (
            <>
              <NavLink to="/messages" dropDown={true}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="fa-lg fa-fw mr-2"
                />
                <span className="mr-2">
                  <FormattedMessage id={"USERMENU_MESSAGES"} />
                </span>
                {badge}
              </NavLink>
              {this.props.systemMenu && <Dropdown.Divider />}
            </>
          )}

          {this.renderShareLink()}

          <NavLink exact to="/" dropDown={true}>
            <FontAwesomeIcon icon={faDoorClosed} className="fa-lg fa-fw mr-2" />
            <FormattedMessage id={"USERMENU_HOME_PAGE"} />
          </NavLink>
          {this.renderSetupHome()}
          <NavLink
            to="#"
            dropDown={true}
            onClick={() => this.props.changePassword()}
          >
            <FontAwesomeIcon icon={faKey} className="fa-lg fa-fw mr-2" />
            <FormattedMessage id={"USERMENU_CHANGE_PASSWORD"} />
          </NavLink>
          {this.props.systemMenu && <Dropdown.Divider />}
          {this.renderSystemMenu()}
          <OverflowDropDown
            className="dropdown-item"
            direction="left"
            title={this.themesTitle()}
            id="themeSelector"
            key={"themeSelector"}
            isSubMenu={true}
          >
            <NavLink
              to="#"
              active={this.isActiveTheme(NptTheme.DEFAULT)}
              dropDown={true}
              onClick={() =>
                !this.isActiveTheme(NptTheme.DEFAULT) &&
                ThemeService.setTheme({
                  platformTheme: NptTheme.DEFAULT,
                  codeEditorTheme: CEditorTheme.DEFAULT,
                })
              }
            >
              <FormattedMessage id={"THEME_DEFAULT"} />
            </NavLink>
            <NavLink
              to="#"
              active={this.isActiveTheme(NptTheme.DEFAULT_COMPACT)}
              dropDown={true}
              onClick={() =>
                !this.isActiveTheme(NptTheme.DEFAULT_COMPACT) &&
                ThemeService.setTheme({
                  platformTheme: NptTheme.DEFAULT_COMPACT,
                  codeEditorTheme: CEditorTheme.DEFAULT,
                })
              }
            >
              <FormattedMessage id={"THEME_DEFAULT_COMPACT"} />
            </NavLink>
            <NavLink
              to="#"
              active={this.isActiveTheme(NptTheme.CUSTOM_DARK)}
              dropDown={true}
              onClick={() =>
                !this.isActiveTheme(NptTheme.CUSTOM_DARK) &&
                ThemeService.setTheme({
                  platformTheme: NptTheme.CUSTOM_DARK,
                  codeEditorTheme: CEditorTheme.MATERIAL,
                })
              }
            >
              <FormattedMessage id={"THEME_CUSTOM_DARK"} />
            </NavLink>
            <NavLink
              to="#"
              active={this.isActiveTheme(NptTheme.CUSTOM_DARK_COMPACT)}
              dropDown={true}
              onClick={() =>
                !this.isActiveTheme(NptTheme.CUSTOM_DARK_COMPACT) &&
                ThemeService.setTheme({
                  platformTheme: NptTheme.CUSTOM_DARK_COMPACT,
                  codeEditorTheme: CEditorTheme.MATERIAL,
                })
              }
            >
              <FormattedMessage id={"THEME_CUSTOM_DARK_COMPACT"} />
            </NavLink>
          </OverflowDropDown>
          {this.renderGoToView(viewPath)}
          {logout && (
            <>
              {this.props.systemMenu && <Dropdown.Divider />}
              <NavLink dropDown={true} className="p-0" to="#">
                <Logout
                  logout={logout}
                  className="p-2"
                  title={
                    <div className="pl-1 ">
                      <FontAwesomeIcon
                        className="fa-lg fa-fw mr-2"
                        icon={faSignOutAlt}
                        size="lg"
                      />
                      <FormattedMessage id={"LOGOUT"} />
                    </div>
                  }
                />
              </NavLink>
            </>
          )}
        </OverflowDropDown>
      </Nav.Item>
    );
  }
}

export const ConnectedUserMenu = connect(
  (state: RootState) => {
    return {
      language: state.locale.language,
      extended: true,
      isSuperUser: isLoggedInUser(state.security.loginStatus)
        ? state.security.loginStatus.superUser
        : false,
      authorities: isLoggedInUser(state.security.loginStatus)
        ? state.security.loginStatus.authorities
        : [],
      userLabel: isLoggedInUser(state.security.loginStatus)
        ? state.security.loginStatus.label
        : "",
      systemMenu: isLoggedInUser(state.security.loginStatus)
        ? state.security.loginStatus.systemMenu
        : undefined,
      mainMenu: isLoggedInUser(state.security.loginStatus)
        ? state.security.loginStatus.mainMenu
        : undefined,
    };
  },
  (dispatch: Dispatch) => {
    return {
      changePassword: () => {
        //thunk actions are not actions because they do not have type field.
        //So we need this ugly hack
        dispatch.menu.changePassword();
      },
      setupHomePage: () => {
        setupHomePage();
      },
      logout: () => {
        dispatch.security.logout();
      },
    };
  }
)(UserMenu);

export const UserLabel = connect((state: RootState) => {
  return {
    userLabel: isLoggedInUser(state.security.loginStatus)
      ? state.security.loginStatus.label
      : "",
  };
})(
  React.memo((props: { userLabel: string }) => {
    return <span style={{ fontSize: ".8rem" }}>{props.userLabel}</span>;
  })
);
